@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.otf');
    font-weight: normal;
    font-style: normal;
}
